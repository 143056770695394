<template>
  <div>
    <div class="content-5emo">
      <div class="label-5emo hvr-grow" v-on:click="response(100)">
        <old-5emo name="icon-joy-gee" size="40" color="#49BB59"></old-5emo>
        <span v-if="question.type === 'kpi'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('ex')}}</app-heading></span>
        <span v-if="question.type === 'csat'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('vs')}}</app-heading></span>
      </div>
      <div class="label-5emo hvr-grow" v-on:click="response(75)">
        <old-5emo  name="icon-happy-gee" size="40" color="#37B0E4"></old-5emo>
        <span v-if="question.type === 'kpi'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('vg')}}</app-heading></span>
        <span v-if="question.type === 'csat'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('sat')}}</app-heading></span>
      </div>
      <div class="label-5emo hvr-grow" v-on:click="response(50)">
        <old-5emo  name="icon-neutral-sleep" size="40" color="#9E9E9E"></old-5emo>
        <span v-if="question.type === 'kpi'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('satisf')}}</app-heading></span>
        <span v-if="question.type === 'csat'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('ind')}}</app-heading></span>
      </div>
      <div class="label-5emo hvr-grow" v-on:click="response(25)">
        <old-5emo  name="icon-unhappy-sleep" size="40" color="#F26C3C"></old-5emo>
        <span v-if="question.type === 'kpi'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('bd')}}</app-heading></span>
        <span v-if="question.type === 'csat'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('un')}}</app-heading></span>
      </div>
      <div class="label-5emo hvr-grow" v-on:click="response(0)">
        <old-5emo  name="icon-wow-open" size="40" color="#EE2E35"></old-5emo>
        <span v-if="question.type === 'kpi'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('vb')}}</app-heading></span>
        <span v-if="question.type === 'csat'"><app-heading level="h5" :style="{ 'color': colors.splashColor}">{{$t('vu')}}</app-heading></span>
      </div>
    </div>
  </div>
</template>

<script>
import { responseHelper, translator } from '@/_helpers'

export default {
  name: 'app5emo',
  props: ['question', 'colors'],
  components: {
    'old-5emo': () => import('@binds-tech/binds-design-system/src/components/Icon/Old5emo'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  created () {
    // seta tradução da question
    if (this.question.language) {
      this.$i18n.locale = translator.getCurrentLocale(this.question.language)
    }
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.response('')
      }
    })
  },
  methods: {
    async response (value) {
      if (typeof value === 'number' && value >= 0) {
        if (this.question.type === 'csat') {
          const label = responseHelper.csat5emoLabels(value)
          this.$emit('value-changed', { rating: value, value: label })
        } else {
          this.$emit('value-changed', { rating: value })
        }
        this.$emit('question-answered', true)
      } else {
        this.$emit('value-changed', { skip: true })
        this.$emit('question-answered', true)
      }
    }
  }
}
</script>
<style lang="scss">
.content-5emo {
  height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .hvr-grow {
    width: 205px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .image-icon {
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .content-5emo {
    height: 290px;
  }
}
</style>
